import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { Link } from 'gatsby'
import cn from 'classnames'

import * as s from './style.module.scss'

import DATA from './constants'

const Token = () => {
  return (
    <Container as="section" className={s.token} id="token">
      <div>
        <h2>SCT Token</h2>
        <p className={s.token__descr}>
          SCT token is the native utility token of the platform. Powered by the Solana blockchain, SCT holders can enjoy
          fast transactions within the platform. SCT is used for governance, payments, and incentives.
        </p>
        <div className={s.token__buttons}>
          <Button as={Link} className={s.token__btn} to="/token">
            Learn more
          </Button>
        </div>
      </div>
      <div className={s.token__chart}>
        <ul className={s.token__items}>
          {DATA.map(({ share, label }) => (
            <li className={cn(s.token__item, s[`_${label.split(' ')[0].toLowerCase()}`])} key={label}>
              <span className={s.token__share}>{share}%</span>
              <span className={s.token__label}>{label}</span>
            </li>
          ))}
        </ul>
        <div className={s.token__donut}>
          <div className={s.token__donut_info}>
            <span>1,000,000,000</span>
            SCT
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Token
