// extracted by mini-css-extract-plugin
export const feature = "x_cp";
export const feature__inner = "x_cq";
export const feature__lead = "x_cr";
export const feature__buttons = "x_cs";
export const feature__pic = "x_ct";
export const feature__items = "x_cv";
export const feature__item = "x_cw";
export const security = "x_cx";
export const encrypt = "x_cy";
export const mining = "x_cz";
export const feature__header = "x_cB";
export const feature__descr = "x_cC";
export const deflat = "x_cD";
export const feature__icon = "x_cF";