import React from 'react'
import { Container } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import sprite from '~img/icons.svg'
import {
  team,
  team__container,
  team__items,
  team__item,
  team__photo,
  team__name,
  team__position,
  team__link,
} from './style.module.scss'

import DATA from './constants'

const Team = () => {
  const data = useStaticQuery(graphql`
    {
      allImageSharp {
        edges {
          node {
            fixed {
              originalName
            }
            gatsbyImageData(
              layout: CONSTRAINED
              width: 296
              height: 296
              quality: 100
              placeholder: BLURRED
              transformOptions: { duotone: { highlight: "#E4EDFF", shadow: "#0D1326" } }
            )
          }
        }
      }
    }
  `)

  return (
    <Container as="section" className={team} id="team">
      <h2 className="text-center">Team</h2>
      <div className={team__container}>
        <div className={team__items}>
          {DATA.map(({ name, position, link }) => {
            const node = data.allImageSharp.edges.find(({ node: { fixed: { originalName } } }) =>
              originalName.includes(name.split(' ')[0])
            )
            return (
              <div className={team__item} key={name}>
                <div className={team__photo}>
                  <GatsbyImage image={node.node.gatsbyImageData} alt={name} style={{ display: 'block' }} />
                </div>
                <p className={team__name}>{name}</p>
                <p className={team__position}>{position}</p>
                {link && (
                  <a className={team__link} target="_blank" rel="noreferrer" href={link}>
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox={`0 0 ${24} ${24}`}>
                      <use fill="currentColor" xlinkHref={`${sprite}#in`} />
                    </svg>
                  </a>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </Container>
  )
}

export default Team
