import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { ButtonSoon } from '~components/ButtonSoon'
import cn from 'classnames'
import * as s from './style.module.scss'
import DATA from './constants'

const Starter = () => {
  return (
    <Container as="section" className={s.starter} id="starter">
      <div className={s.starter__inner}>
        <div className={s.starter__pic}>
          <StaticImage
            style={{ display: 'block' }}
            src="./img/screen.png"
            alt="Screen"
            placeholder="tracedSVG"
            quality={100}
            width={279}
            height={550}
          />
        </div>
        <div>
          <h2>SolClout Starter</h2>
          <p className={s.starter__lead}>
            SolClout Stаrter is a messenger-based IDO platform. Social networking based IDO platform, SolClout Starter
            allows for projects to establish official communities. Users with best activity could receive an allocation
            for the initial decentralized offering.
          </p>
          <div className={s.starter__buttons}>
            <Button as={Link} to="/starter">
              Learn more
            </Button>
            <ButtonSoon />
          </div>
        </div>
      </div>
      <div className={s.starter__container}>
        <div className={s.starter__items}>
          {DATA.map(({ mod, title, descr }) => (
            <div className={cn(s.starter__item, s[mod])} key={mod}>
              <h3 className={s.starter__title}>{title}</h3>
              <p>{descr}</p>
            </div>
          ))}
        </div>
      </div>
    </Container>
  )
}

export default Starter
