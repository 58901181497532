import React from 'react'
import { Container } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { partners, partners__container, partners__items, partners__pic, partners__name } from './style.module.scss'

import DATA from './constants'

const Partners = () => {
  const data = useStaticQuery(graphql`
    {
      allImageSharp {
        edges {
          node {
            fixed {
              originalName
            }
            gatsbyImageData(layout: CONSTRAINED, width: 183, quality: 100, placeholder: TRACED_SVG)
          }
        }
      }
    }
  `)

  return (
    <Container as="section" className={partners} id="partners">
      <h2 className="text-center">Partners</h2>
      <div className={partners__container}>
        <div className={partners__items}>
          {DATA.map((item) => {
            const node = data.allImageSharp.edges.find(({ node: { fixed: { originalName } } }) =>
              originalName.includes(item.split(' ').join(''))
            )
            return (
              <div key={item}>
                <div className={partners__pic}>
                  <GatsbyImage image={node.node.gatsbyImageData} alt={item} style={{ display: 'block' }} />
                </div>
                <p className={partners__name}>{item}</p>
              </div>
            )
          })}
        </div>
      </div>
    </Container>
  )
}

export default Partners
