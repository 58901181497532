const DATA = [
  {
    icon: 'security',
    title: 'Censorship Free',
    descr:
      'All users on SolClout create accounts in an anonymous manner. Users are not asked of personal information prior to creating a contact.',
  },
  {
    icon: 'encrypt',
    title: 'Encryption',
    descr:
      'With account generation to messaging all on-chain, enjoy the most decentralized and encrypted social networking service powered by blockchain technology.',
  },
  {
    icon: 'mining',
    title: 'Decentralized Browsing',
    descr: 'With your personalized, decentralized account enjoy private browsing powered by blockchain technology.',
  },
  {
    icon: 'deflat',
    title: 'Finance',
    descr:
      'On the personal decentralized wallet, users can send, receive and store SCT and any other Solana based cryptocurrencies.',
  },
]

export default DATA
