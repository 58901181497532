import React from 'react'
import { Modal as ReactBootstrapModal } from 'react-bootstrap'
import { Form } from '~components/Form'

export const Modal = ({ show, handleClose }) => {
  return (
    <ReactBootstrapModal centered show={show} onHide={handleClose}>
      <button className="modal-close" onClick={handleClose} />
      <Form popup handleClose={handleClose} />
    </ReactBootstrapModal>
  )
}
