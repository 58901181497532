import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { ButtonSoon } from '~components/ButtonSoon'
import { world, world__pic, world__lead, world__buttons, world__btn } from './style.module.scss'

const World = () => {
  return (
    <Container as="section" className={world} id="world">
      <div>
        <h2>SolClout World</h2>
        <p className={world__lead}>
          SolClout World is a virtual world that allows for users to purchase land space to exhibit projects,
          advertisements, or interact with other users. SolClout World is the perfect implementation of metaverse,
          virtual reality, and blockchain to allow blockchain projects to build a virtual ecosystem to interact with
          other projects, investors, and influencers.
        </p>
        <div className={world__buttons}>
          <Button as={Link} to="/world">
            Learn more
          </Button>
          <ButtonSoon />
        </div>
      </div>
      <div className={world__pic}>
        <StaticImage
          style={{ display: 'block' }}
          src="./img/ranktrades.png"
          alt="Leaderboard"
          placeholder="tracedSVG"
          quality={100}
          width={279}
          height={550}
        />
      </div>
    </Container>
  )
}

export default World
