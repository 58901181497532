// extracted by mini-css-extract-plugin
export const roadmap = "v_bM";
export const roadmap_inner = "v_bN";
export const roadmap__container = "v_bP";
export const roadmap__list = "v_bQ";
export const roadmap__item = "v_bR";
export const current = "v_bS";
export const roadmap__descr = "v_bT";
export const roadmap__header = "v_bV";
export const december = "v_bW";
export const q1 = "v_bX";
export const q3 = "v_bY";