const DATA = [
  'EcoSerum',
  'ExNetwork',
  'Axis Capital',
  'MoonWhale',
  'BMW Capital',
  'PolkaBridge',
  'ChinaPolka',
  'Dreamboat Capital',
]

export default DATA
