const DATA = [
  {
    share: 1,
    label: 'DEX TGE',
  },
  {
    share: 2,
    label: 'Public Sale',
  },
  {
    share: 3,
    label: 'Advisors',
  },
  {
    share: 5,
    label: 'Seed Round',
  },
  {
    share: 7,
    label: 'Strategic Sale',
  },
  {
    share: 10,
    label: 'Liquidity Pool Reward',
  },
  {
    share: 10,
    label: 'Private Sale',
  },
  {
    share: 12,
    label: 'Ecosystem / incentives',
  },
  {
    share: 15,
    label: 'Reserves',
  },
  {
    share: 15,
    label: 'Team',
  },
  {
    share: 20,
    label: 'Marketing',
  },
]

export default DATA
