import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { Link } from 'gatsby'
import { about, about__descr, about__buttons, about__btn, about__pic } from './style.module.scss'
import aboutImg from '~img/about.svg'

const About = () => {
  return (
    <Container as="section" className={about} id="about">
      <div className={about__pic}>
        <img src={aboutImg} alt="Phone Illsutration" />
      </div>
      <div>
        <h2>About</h2>
        <p className={about__descr}>
          SolClout is envisioning a platform to build a formidable community powered by Solana, a high throughput,
          scalable public blockchain project. SolClout will allow for cryptocurrency projects and users to create and
          boot-strap Clout-driven communities geared towards building fast and sustainable social presences.
        </p>
        <div className={about__buttons}>
          <Button as={Link} className={about__btn} to="/about">
            Learn more
          </Button>
        </div>
      </div>
    </Container>
  )
}

export default About
