import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Modal } from '~components/Modal'

export const ButtonSoon = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(null)
  const openPopup = () => setIsPopupOpen(true)
  const closePopup = () => setIsPopupOpen(false)
  return (
    <>
      <Button variant="hollow" onClick={openPopup}>
        Release soon
      </Button>
      <Modal show={isPopupOpen} handleClose={closePopup} />
    </>
  )
}
