const DATA = [
  {
    name: 'Malay Tripathi',
    position: 'CEO / CTO',
    link: 'https://www.linkedin.com/in/malaytripathi/',
  },
  {
    name: 'Sunday Adedeji',
    position: 'Lead Marketing',
    link: 'https://www.linkedin.com/in/sunday-adedeji-5ab083156/',
  },
]

export default DATA
