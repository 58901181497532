// extracted by mini-css-extract-plugin
export const technology = "s_n";
export const technology__descr = "s_bl";
export const technology__buttons = "s_bm";
export const technology__btn = "s_bn";
export const technology__container = "s_bp";
export const technology__items = "s_bq";
export const technology__item = "s_br";
export const app = "s_bs";
export const service = "s_bt";
export const core = "s_bv";
export const technology__header = "s_bw";
export const technology__list = "s_bx";