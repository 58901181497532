const DATA = [
  { name: 'app', title: 'Application Layer', list: ['Content Sharing', 'User Interface'] },
  {
    name: 'service',
    title: 'Service Layer',
    list: ['Transaction Fees', 'Payment'],
  },
  {
    name: 'core',
    title: 'Core Layer',
    list: ['Solana Blockchain Core'],
  },
]

export default DATA
