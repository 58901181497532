const DATA = [
  {
    mod: 'upvote',
    title: 'Upvote',
    descr: 'Users can vote for best messages showcasing knowledge of the project.',
  },
  {
    mod: 'spam',
    title: 'Anti-Spam & Speculation',
    descr: 'Based on upvote function and chat activity, project owners can avoid spam and speculation.',
  },
  {
    mod: 'quiz',
    title: 'Quiz',
    descr:
      'Within chat groups, project owners can operate blockchain-based quizzes to test project expertise of users.',
  },
  {
    mod: 'tech',
    title: 'Blockchain Technology',
    descr: 'With blockchain implementation voting is decentralized with blockchain verification.',
  },
]

export default DATA
