import React from 'react'
import { Container } from 'react-bootstrap'
import cn from 'classnames'
import * as s from './style.module.scss'

import DATA from './constants'

const Roadmap = () => {
  return (
    <section className={s.roadmap} id="roadmap">
      <Container className={s.roadmap_inner}>
        <h2 className="text-center">Roadmap</h2>
        <div className={s.roadmap__container}>
          <ul className={s.roadmap__list}>
            {DATA.map(({ title, list, current }) => {
              return (
                <li className={cn(s.roadmap__item, { [s.current]: current })} key={title}>
                  <h3 className={s.roadmap__header}>{title}</h3>
                  <ul className={s.roadmap__descr}>
                    {list.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </li>
              )
            })}
          </ul>
        </div>
      </Container>
    </section>
  )
}

export default Roadmap
