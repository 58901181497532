// extracted by mini-css-extract-plugin
export const token = "w_z";
export const token__descr = "w_bZ";
export const token__chart = "w_b0";
export const token__items = "w_b1";
export const token__donut = "w_b2";
export const token__donut_info = "w_b3";
export const token__item = "w_b4";
export const _dex = "w_b5";
export const _public = "w_b6";
export const _advisors = "w_b7";
export const _seed = "w_b8";
export const _strategic = "w_b9";
export const _liquidity = "w_cb";
export const _private = "w_cc";
export const _ecosystem = "w_cd";
export const _reserves = "w_cf";
export const _team = "w_cg";
export const _marketing = "w_ch";
export const token__item__label = "w_cj";
export const token__share = "w_ck";
export const token__label = "w_cl";
export const token__buttons = "w_cm";
export const token__btn = "w_cn";