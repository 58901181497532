import React from 'react'
import { Container } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { ButtonSoon } from '~components/ButtonSoon'
import cn from 'classnames'
import * as s from './style.module.scss'

import DATA from './constants'

import sprite from '~img/icons.svg'

const Feature = () => {
  return (
    <Container as="section" className={s.feature} id="feature">
      <div className={s.feature__inner}>
        <div className={s.feature__pic}>
          <StaticImage
            style={{ display: 'block' }}
            src="./img/feed.png"
            alt="Feed"
            placeholder="tracedSVG"
            quality={100}
            width={279}
            height={550}
          />
        </div>
        <div>
          <h2>SolClout Messenger</h2>
          <p className={s.feature__lead}>
            SolClout Messenger is a simple blockchain-based social networking feature embedded within the application.
            Users can enjoy a seamless user experience. Powered by blockchain technology, users are able to also utilize
            a social networking platform free from censorship.
          </p>
          <div className={s.feature__buttons}>
            <ButtonSoon />
          </div>
        </div>
      </div>
      <div className={s.feature__items}>
        {DATA.map(({ icon, title, descr }) => (
          <div className={cn(s.feature__item, s[icon])} key={icon}>
            <div className={s.feature__icon}>
              <svg xmlns="http://www.w3.org/2000/svg" width={150} height={150} viewBox={`0 0 ${150} ${150}`}>
                <use fill="currentColor" xlinkHref={`${sprite}#${icon}`} />
              </svg>
            </div>
            <h3 className={s.feature__header}>{title}</h3>
            <p className={s.feature__descr}>{descr}</p>
          </div>
        ))}
      </div>
    </Container>
  )
}

export default Feature
