import * as React from 'react'
import { Button } from 'react-bootstrap'
import Layout from '~components/Layout'
import SEO from '~components/seo'
import Hero from '~components/Hero'

import About from './components/About'
import Technology from './components/Technology'
import Feature from './components/Feature'
import World from './components/World'
import Starter from './components/Starter'
import Token from './components/Token'
import Roadmap from './components/Roadmap'
import Team from './components/Team'
import Partners from './components/Partners'

import DATA from './data'
import deck from '~assets/pdf/deck.pdf'

import { hero__buttons, hero__btn } from './styles.module.scss'

export const Home = () => {
  return (
    <Layout hasFooterSpot hasNoise>
      <SEO />
      <Hero {...DATA.HERO} modifier="home" hasBuilt>
        <div className={hero__buttons}>
          <Button className={hero__btn} as="a" href={deck} target="_blank" rel="noreferrer">
            Read Pitch Deck
          </Button>
        </div>
      </Hero>
      <About />
      <Technology />
      <Feature />
      <World />
      <Starter />
      <Token />
      <Roadmap />
      <Partners />
      <Team />
    </Layout>
  )
}
