// extracted by mini-css-extract-plugin
export const starter = "t_y";
export const starter__inner = "t_by";
export const starter__lead = "t_bz";
export const starter__pic = "t_bB";
export const starter__buttons = "t_bC";
export const starter__container = "t_bD";
export const starter__items = "t_bF";
export const starter__item = "t_bG";
export const upvote = "t_bH";
export const spam = "t_bJ";
export const quiz = "t_bK";
export const tech = "t_bL";
export const starter__title = "t_R";