const DATA = [
  {
    title: '2020 October',
    list: ['Market Research', 'Platform Design'],
  },
  {
    title: '2020 DECEMBER',
    list: ['Alpha Release of SolClout Chat'],
  },
  {
    title: '2021 Q1',
    list: ['EcoSerum Grant', 'IDO Platform UI/UX Research'],
  },
  {
    title: '2021 Q2',
    list: ['Product Research', 'Seed Funding'],
  },
  {
    title: '2021 Q3',
    list: [
      'IDO Platform Beta',
      'Fundraiser',
      'Telegram Integration Research',
      'Multichain AMM R&D',
      'SolClout Social R&D',
    ],
  },
  {
    title: '2021 Q4',
    list: [
      'Token Launch',
      'IDO Platform Launch',
      'SolClout AMM Beta Release',
      'SolClout Social Alpha Release',
      'SolClout NFT R&D',
    ],
    current: true,
  },
  {
    title: '2022 Q1-Q2',
    list: ['Social IDO Function Release', 'SolClout DMessenger Release', 'SolClout NFT Alpha Release'],
  },
  {
    title: '2022 Q3',
    list: ['SolClout World Alpha Release'],
  },
]

export default DATA
