import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { Link } from 'gatsby'
import cn from 'classnames'
import * as s from './style.module.scss'

import DATA from './constants'

const Technology = () => {
  return (
    <Container as="section" className={s.technology} id="technology">
      <h2 className="text-center">Technology</h2>
      <p className={s.technology__descr}>
        SolClout is built on Solana, a scalable public blockchain platform. We thrive for complete decentralization on
        all aspects without any central component.
      </p>
      <div className={s.technology__buttons}>
        <Button as={Link} className={s.technology__btn} to="/technology">
          Learn more
        </Button>
      </div>
      <div className={s.technology__container}>
        <div className={s.technology__items}>
          {DATA.map(({ name, title, list }) => (
            <div className={cn(s.technology__item, s[name])} key={name}>
              <h3 className={s.technology__header}>{title}</h3>
              <ul className={s.technology__list}>
                {list.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </Container>
  )
}

export default Technology
